<template>
  <div class="mynews-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="form" v-if="userInfo">
      <div class="form-main">
        <div class="form-title">接收消息设置</div>
        <!-- 邮箱绑定 -->
        <div class="card">
          <div class="left">
            <div class="left-title">邮箱</div>
            <div class="info">
              <div v-if="!userInfo.projectMail">未绑定</div>
              <div v-else class="isbinding">
                <div class="isbinding-name">
                  {{ userInfo.projectMail }}
                </div>
                <div class="resetting" @click="handleShow">重新绑定</div>
              </div>
              <div>用于邮件接收平台提醒（项目配额更新、财务收款信息等）</div>
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="userInfo.enabledProjectMail"
              active-color="#4578FF"
              inactive-color="#BCBCBC"
              :disabled="!userInfo.projectMail"
              @change="changeMailSubscribe($event, 2)"
            >
            </el-switch>
            <div
              class="name"
              v-show="!userInfo.projectMail"
              @click="handleShow"
            >
              绑定邮箱
            </div>
          </div>
        </div>
        <!-- 微信公众号 -->
        <div class="card">
          <div class="left">
            <div class="left-title">微信公众号</div>
            <div class="info">
              <div v-if="!weChatSwitch">未绑定</div>
              <div v-else class="isbinding">
                <div class="isbinding-name">wechat</div>
                <div class="resetting">重置</div>
              </div>
              <div>用于微信公众号平台提醒</div>
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="weChatSwitch"
              active-color="#4578FF"
              inactive-color="#BCBCBC"
              disabled
            >
            </el-switch>
            <div class="name" v-show="!weChatSwitch">绑定微信</div>
          </div>
        </div>
        <!-- 手机 -->
        <div class="card">
          <div class="left">
            <div class="left-title">手机</div>
            <div class="info">
              <div v-if="!userInfo.projectPhone">未绑定</div>
              <div v-else class="isbinding">
                <div class="isbinding-name">{{ userInfo.projectPhone }}</div>
                <div class="resetting" @click="phoneHandleShow">重新绑定</div>
              </div>
              <div>用于手机短信接收消息提醒</div>
            </div>
          </div>
          <div class="right">
            <el-switch
              v-model="userInfo.enabledProjectSms"
              active-color="#4578FF"
              inactive-color="#BCBCBC"
              :disabled="!userInfo.projectPhone"
              @change="changeMailSubscribe($event, 3)"
            >
            </el-switch>
            <div
              class="name"
              @click="phoneHandleShow"
              v-show="!userInfo.projectPhone"
            >
              绑定手机号
            </div>
          </div>
        </div>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
    <!-- 邮箱验证 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="480px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <img
        class="close"
        @click="handleClose"
        src="@/assets/images/close.png"
        alt="图片"
      />
      <div class="title">邮箱绑定</div>

      <div class="input-box">
        <div class="name">邮箱</div>
        <el-input v-model="inputValue" placeholder="`请输入邮箱"   maxlength="1000"></el-input>
      </div>
      <drag-verify
        v-if="emaliVerify"
        style="margin: 20px 0 0 92px"
        :width="324"
        ref="dragVerify"
        handlerBg="#4578FF"
        :isPassing.sync="isPassing"
        text="请向右滑动验证"
        successText="验证通过"
        handlerIcon="el-icon-d-arrow-right"
        successIcon="el-icon-circle-check"
      >
        <i v-show="!isPassing" slot="textBefore" class="el-icon-lock"></i>
      </drag-verify>
      <div class="input-box">
        <div class="name">验证码：</div>
        <el-input v-model="code" maxlength="4" placeholder="请输入验证码">
          <template slot="append">
            <div @click="getCode">
              {{ timer ? `${number}s后重新获取` : "获取验证码" }}
            </div>
          </template>
        </el-input>
      </div>

      <el-button @click="setProjectMail(1)">设置</el-button>
    </el-dialog>
    <!-- 手机号验证 -->
    <el-dialog
      title="提示"
      :visible.sync="phoneVisible"
      width="480px"
      :before-close="phoneHandleClose"
      :close-on-click-modal="false"
    >
      <img
        class="close"
        @click="phoneHandleClose"
        src="@/assets/images/close.png"
        alt="图片"
      />
      <div class="title">手机号绑定</div>

      <div class="input-box">
        <div class="name">手机号</div>
        <el-input v-model="phoneValue" placeholder="`请输入手机号"   maxlength="11"></el-input>
      </div>
      <drag-verify
        v-if="phoneVerify"
        style="margin: 20px 0 0 92px"
        :width="324"
        ref="dragVerify"
        handlerBg="#4578FF"
        :isPassing.sync="phonePassing"
        text="请向右滑动验证"
        successText="验证通过"
        handlerIcon="el-icon-d-arrow-right"
        successIcon="el-icon-circle-check"
      >
        <i v-show="!phonePassing" slot="textBefore" class="el-icon-lock"></i>
      </drag-verify>
      <div class="input-box">
        <div class="name">验证码：</div>
        <el-input v-model="phoneCode" placeholder="请输入验证码" maxlength="4">
          <template slot="append">
            <div @click="phoneGetCode">
              {{ phoneTimer ? `${phoneNumber}s后重新获取` : "获取验证码" }}
            </div>
          </template>
        </el-input>
      </div>

      <el-button @click="setProjectPhone">设置</el-button>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import {
  setBindMailCode,
  setProjectMail,
  setSubscribe,
  setBindMobileCode,
  setProjectPhone,
} from "@/api/app";
import { getProfile } from "@/api/user";
export default {
  components: { Breadcrumb, Footer },
  data() {
    return {
      list: [
        {
          name: "消息管理",
          path: null,
        },
        {
          name: "消息设置",
          path: null,
        },
      ],
      emailSwitch: false,
      weChatSwitch: false,
      phoneSwitch: false,
      dialogVisible: false,
      inputValue: null, //输入框内容
      isPassing: false, //滑动验证
      code: null, //验证码
      timer: null,
      number: 300,
      emaliVerify: true,

      phoneVisible: false,
      phoneValue: null, //输入框内容
      phonePassing: false, //滑动验证
      phoneCode: null, //验证码
      phoneTimer: null,
      phoneNumber: 60,
      phoneVerify: true,
    };
  },
  created() {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    //邮箱弹出框显示事件
    handleShow() {
      this.dialogVisible = true;
      this.emaliVerify = true;
    },
    //邮箱弹出框关闭事件
    handleClose() {
      this.dialogVisible = false;
      if (this.timer === null) {
        this.isPassing = false;
        this.emaliVerify = false;
        console.log("触发isPassing");
      }
    },
    //邮箱点击获取验证码
    getCode() {
      if (!this.isPassing) {
        this.$message({
          message: "请先完成滑块验证",
          type: "warning",
        });
        return;
      }
      if (!this.timer) {
        const params = {
          name: this.inputValue,
        };
        setBindMailCode(params).then((res) => {
          console.log(1234, res);
          if (res.status === 200) {
            this.getTime();
            this.number = 300;
          }
        });
      }
    },
    //邮箱get
    getTime() {
      this.timer = setInterval(() => {
        if (this.number === 1) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.number = this.number - 1;
      }, 1000);
    },
    //绑定邮箱
    setProjectMail(type) {
      const params = {
        projectMail: this.inputValue,
        code: this.code,
      };
      setProjectMail(params).then((res) => {
        console.log("邮箱绑定", res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "邮箱绑定成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.isPassing = false;
          this.emaliVerify = false;
          clearInterval(this.timer);
          this.timer = null;
          this.inputValue = null;
          this.code = null;
          this.getProfile();
        }
      });
    },
    //订阅
    changeMailSubscribe(e, type) {
      console.log(e, type);
      const params = {
        cateId: type,
        email: this.userInfo.enabledProjectMail,
        sms: this.userInfo.enabledProjectSms,
      };
      setSubscribe(params).then((res) => {
        if (res.status === 200) {
          this.getProfile();
          this.$message({
            showClose: true,
            message: e ? "订阅成功" : "取消订阅成功",
            type: "success",
          });
        }
      });
    },
    //获取个人基础信息
    getProfile() {
      getProfile().then((res) => {
        if (res.status === 200) {
          this.$store.commit("user/setUserInfo", res.data);
          console.log(res.data);
        }
      });
    },
    //手机号弹出框显示事件
    phoneHandleShow() {
      this.phoneVisible = true;
      this.phoneVerify = true;
    },
    //手机号弹出框关闭事件
    phoneHandleClose() {
      this.phoneVisible = false;
      if (this.phoneTimer === null) {
        this.phonePassing = false;
        this.phoneVerify = false;
      }
    },
    //手机号点击获取验证码
    phoneGetCode() {
      if (!this.phonePassing) {
        this.$message({
          message: "请先完成滑块验证",
          type: "warning",
        });
        return;
      }
      if (!this.phoneTimer) {
        const params = {
          name: this.phoneValue,
        };
        setBindMobileCode(params).then((res) => {
          console.log(1234, res);
          if (res.status === 200) {
            this.phoneGetTime();
            this.phoneNumber = 300;
          }
        });
      }
    },
    //手机号get
    phoneGetTime() {
      this.phoneTimer = setInterval(() => {
        if (this.phoneNumber === 1) {
          clearInterval(this.phoneTimer);
          this.phoneTimer = null;
        }
        this.phoneNumber = this.phoneNumber - 1;
      }, 1000);
    },
    //绑定手机号
    setProjectPhone() {
      const params = {
        projectPhone: this.phoneValue,
        code: this.phoneCode,
      };
      setProjectPhone(params).then((res) => {
        console.log("手机号绑定", res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "手机号绑定成功",
            type: "success",
          });
          this.phoneVisible = false;
          this.phonePassing = false;
          this.phoneVerify = false;
          clearInterval(this.phoneTimer);
          this.phoneTimer = null;
          this.phoneValue = null;
          this.phoneCode = null;
          this.getProfile();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mynews-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);
  .form {
    min-height: calc(100vh - 153px);
    background-color: #fff;
    margin-top: 20px;
    .form-main {
      display: flex;
      flex-direction: column;
      .form-title {
        margin: 24px 0 51px 24px;
        font-weight: bold;
      }
      .card {
        width: calc(100% - 48px);
        display: flex;
        justify-content: space-between;
        height: 110px;
        background: #fafafa;
        margin: 0 24px;
        margin-bottom: 30px;
        .left {
          display: flex;
          .left-title {
            margin: 30px 0 0 24px;
            font-size: 14px;
            color: #06052b;
            width: 150px;
          }
          .info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            margin-left: 20px;
            div {
              color: #9091a1;
              font-size: 14px;
              margin-bottom: 22px;
            }
            .isbinding {
              display: flex;
              .isbinding-name {
                width: 200px;
                margin-bottom: 0;
                color: #4578ff;
              }
              .resetting {
                margin-bottom: 0;
                cursor: pointer;
              }
            }
          }
        }
        .right {
          display: flex;
          width: 240px;
          .el-switch {
            margin-top: 30px;
          }
          .name {
            color: #4578ff;
            font-size: 14px;
            margin-right: 0px;
            margin-left: 51px;
            margin-top: 30px;
            cursor: pointer;
            // width: 150px;
          }
        }
      }
    }
    .footer-fa {
      padding-right: 20px;
      background-color: #fff;
      margin-top: 150px;
      /deep/ .home-page-footer {
        margin-bottom: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
}
.el-dialog__wrapper {
  /deep/ .el-dialog__header {
    display: none;
  }
  /deep/ .el-dialog__body {
    padding: 20px;
  }
  .close {
    float: right;
    width: 14px;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #06052b;
  }
  .input-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #06052b;
    .name {
      width: 80px;
      text-align: right;
      margin-right: 12px;
    }
    .el-input {
      width: 324px;
    }
    /deep/ .el-input__inner {
      height: 36px;
    }
    /deep/.el-input-group__append {
      cursor: pointer;
    }
  }
  .el-button {
    width: 395px;
    height: 36px;
    background: #4578ff;
    border-radius: 4px;
    padding: 0;
    margin-left: 22px;
    margin-top: 20px;
    color: #fff;
  }
}
</style>